import React, { useState, useRef } from 'react';
import Grid from '@mui/material/Grid2';

import { } from './whatsnew.css'
import styles from './whatsnew.module.css';
import { Typography, Link } from '@mui/material';
import { Tabs, Tab } from "react-tabs-scrollable";
import { East, West } from '@mui/icons-material';


const NewsArchieve = () => {

    const [value, setValue] = useState(0);
    const tabRef = useRef(null);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    }


    const TabScreen = ({ activeTab, idx, ...props }) => {
        return (
          <div
            className="animate__animated animate__fadeInLeft"
            role="tabpanel"
            {...props}
          >
            {activeTab === idx && <div className="mx-4">{props.children}</div>}
          </div>
        );
      };


    return (
        <Grid className={styles.whatsnew_container} sx={{ backgroundColor: "#DAF0FF", paddingTop: "100px", paddingBottom: "100px"}}>
            <Grid className={styles.whatsnew_subcontainer}>
                <Typography variant='h3' component={"h3"} className={styles.whatsnew_title}>
                    News Archieves
                </Typography>
                <Grid sx={{marginTop:'3rem'}}>
                  <Tabs
                  activeTab={value}
                  action={tabRef}
                  onTabClick={handleChange}
                  tabsScrollAmount={3}
                  rightBtnIcon={<East color="#2182FF" fontSize='24px' />}
                  leftBtnIcon={<West color="#2182FF" fontSize='24px' />}
                  hideNavBtns={false}
                  hideNavBtnsOnMobile={false}
                  showTabsScroll={false}
                  >
                    <Tab key={'2024'} > 2024</Tab>
                    <Tab key={'2023'} > 2023</Tab>
                    <Tab key={'2019'} > 2022</Tab>
                    <Tab key={'2018'} > 2021</Tab>
                    <Tab key={'2017'} > 2020</Tab>
                    <Tab key={'2016'} > 2019</Tab>
                    <Tab key={'2015'} > 2018</Tab>
                  </Tabs>
                
                    <Grid sx={{marginTop:'3rem'}}>
                      <TabScreen activeTab={value} idx={0} key={"2024"}>
                        <Grid className={styles.tabpanel_container}>
                              <Typography variant='body2' component={"p"} className={styles.news_content_title}>
                                Adventz Group’s Simon India and Belgium’s EcoPhos forge global EPC partnership,
                              </Typography>
                              <Grid className={styles.tabpanel_grid}>
                                <Grid sx={{width: {sm:"40%", xs:"100%", display: 'flex', justifyContent: 'space-between'}}}>
                                    <Typography variant='body2' component={"p"} className={styles.whatsnew_content}>
                                        June 26, 2018
                                    </Typography>
                                    <Typography variant='body2' component={"p"} className={styles.whatsnew_content}>
                                        The Economic Times
                                    </Typography>
                                    <Typography variant='body2' component={"p"} className={styles.whatsnew_content}>
                                        Mumbai
                                    </Typography>
                                </Grid>
                                <Grid sx={{width: {sm:"60%", xs:"100%"}, textAlign:"right"}}><Link href="/#"><img src='/assets/images/news_button.png' alt="" /></Link></Grid>
                              </Grid>
                          </Grid>
                      </TabScreen>
                      <TabScreen activeTab={value} idx={1} key={"2023"}>
                      <Grid className={styles.tabpanel_container}>
                              <Typography variant='body2' component={"p"} className={styles.news_content_title}>
                                Adventz Group’s Simon India and Belgium’s EcoPhos forge global EPC partnership,
                              </Typography>
                              <Grid className={styles.tabpanel_grid}>
                                <Grid sx={{width: {sm:"40%", xs:"100%", display: 'flex', justifyContent: 'space-between'}}}>
                                    <Typography variant='body2' component={"p"} className={styles.whatsnew_content}>
                                        June 26, 2018
                                    </Typography>
                                    <Typography variant='body2' component={"p"} className={styles.whatsnew_content}>
                                        The Economic Times
                                    </Typography>
                                    <Typography variant='body2' component={"p"} className={styles.whatsnew_content}>
                                        Mumbai
                                    </Typography>
                                </Grid>
                                <Grid sx={{width: {sm:"60%", xs:"100%"}, textAlign:"right"}}><Link href="/#"><img src='/assets/images/news_button.png' alt="" /></Link></Grid>
                              </Grid>
                          </Grid>
                      </TabScreen>
                      <TabScreen activeTab={value} idx={2} key={"2022"}>
                      <Grid className={styles.tabpanel_container}>
                              <Typography variant='body2' component={"p"} className={styles.news_content_title}>
                                Adventz Group’s Simon India and Belgium’s EcoPhos forge global EPC partnership,
                              </Typography>
                              <Grid className={styles.tabpanel_grid}>
                                <Grid sx={{width: {sm:"40%", xs:"100%", display: 'flex', justifyContent: 'space-between'}}}>
                                    <Typography variant='body2' component={"p"} className={styles.whatsnew_content}>
                                        June 26, 2018
                                    </Typography>
                                    <Typography variant='body2' component={"p"} className={styles.whatsnew_content}>
                                        The Economic Times
                                    </Typography>
                                    <Typography variant='body2' component={"p"} className={styles.whatsnew_content}>
                                        Mumbai
                                    </Typography>
                                </Grid>
                                <Grid sx={{width: {sm:"60%", xs:"100%"}, textAlign:"right"}}><Link href="/#"><img src='/assets/images/news_button.png' alt="" /></Link></Grid>
                              </Grid>
                          </Grid>
                      </TabScreen>
                      <TabScreen activeTab={value} idx={3} key={"2021"}>
                      <Grid sx={{display: 'flex', gap:'30px', width:"80%"}}></Grid>
                      </TabScreen>
                      <TabScreen activeTab={value} idx={4} key={"2020"}>
                      <Grid sx={{display: 'flex', gap:'30px', width:"80%"}}></Grid>
                      </TabScreen>
                      <TabScreen activeTab={value} idx={5} key={"2019"}>
                      <Grid sx={{display: 'flex', gap:'30px', width:"80%"}}></Grid>
                      </TabScreen>
                      <TabScreen activeTab={value} idx={6} key={"2018"}>
                      <Grid sx={{display: 'flex', gap:'30px', width:"80%"}}></Grid>
                      </TabScreen>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )


}

export default NewsArchieve;