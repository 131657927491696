import React from 'react';
import Grid from '@mui/material/Grid2';
import { Typography, Link } from '@mui/material';
import styles from './projectGallery.module.css';


const ProjectNews = () => {

    return (
        <Grid className={`${styles.projectgallery_container} ${styles.project_background}`} sx={{paddingBottom: "80px", paddingTop: "80px"}}>
            <Grid className={styles.projectgallery_subcontainer}>
                <Grid className={styles.tabpanel_container}>
                    <Grid sx={{width:"90%"}}>
                        <Typography variant='body2' tabpanel_gridcomponent={"p"} className={styles.news_content_title}>
                            Simon India Team Gains Insight in Procurement with Expert-Led Session (H3)
                        </Typography>
                        <Grid className={styles.news_container}>
                            <Typography variant='body2' component={"p"} className={styles.whatsnew_content}>
                            August 10, 2024
                            </Typography>
                            {/* <Grid className={styles.news_line}>&nbsp;</Grid>
                            <Typography variant='body2' component={"p"} className={styles.whatsnew_content}>
                                The Economic Times
                            </Typography>
                            <Grid className={styles.news_line}>&nbsp;</Grid>
                            <Typography variant='body2' component={"p"} className={styles.whatsnew_content}>
                                Mumbai
                            </Typography> */}
                        </Grid>
                    </Grid>
                    
                    <Grid sx={{width: {xs:"10%"}, textAlign:"right"}}>
                        {/* <Link href="/#"><img src='/assets/images/white_news_button.png' alt="" /></Link> */}
                    </Grid>
                </Grid>
                <Grid className={styles.tabpanel_container}>
                    <Grid sx={{width:"90%"}}>
                        <Typography variant='body2' tabpanel_gridcomponent={"p"} className={styles.news_content_title}>
                            Simon India Participates in New Delhi Roundtable on Hydrogen Technology (H3)
                        </Typography>
                        <Grid className={styles.news_container}>
                            <Typography variant='body2' component={"p"} className={styles.whatsnew_content}>
                            August 10, 2024
                            </Typography>
                            {/* <Grid className={styles.news_line}>&nbsp;</Grid>
                            <Typography variant='body2' component={"p"} className={styles.whatsnew_content}>
                                The Economic Times
                            </Typography>
                            <Grid className={styles.news_line}>&nbsp;</Grid>
                            <Typography variant='body2' component={"p"} className={styles.whatsnew_content}>
                                Mumbai
                            </Typography> */}
                        </Grid>
                    </Grid>
                    
                    <Grid sx={{width: {xs:"10%"}, textAlign:"right"}}>
                        {/* <Link href="/#"><img src='/assets/images/white_news_button.png' alt="" /></Link> */}
                    </Grid>
                </Grid>
                <Grid className={styles.tabpanel_container}>
                    <Grid sx={{width:"90%"}}>
                        <Typography variant='body2' tabpanel_gridcomponent={"p"} className={styles.news_content_title}>
                        Simon India Limited Secures Major Contract with Paradeep Phosphates Limited (H3)
                        </Typography>
                        <Grid className={styles.news_container}>
                            <Typography variant='body2' component={"p"} className={styles.whatsnew_content}>
                            July 31, 2024
                            </Typography>
                            {/* <Grid className={styles.news_line}>&nbsp;</Grid>
                            <Typography variant='body2' component={"p"} className={styles.whatsnew_content}>
                                The Economic Times
                            </Typography>
                            <Grid className={styles.news_line}>&nbsp;</Grid>
                            <Typography variant='body2' component={"p"} className={styles.whatsnew_content}>
                                Mumbai
                            </Typography> */}
                        </Grid>
                    </Grid>
                    
                    <Grid sx={{width: {xs:"10%"}, textAlign:"right"}}>
                        {/* <Link href="/#"><img src='/assets/images/white_news_button.png' alt="" /></Link> */}
                    </Grid>
                </Grid>
                
            </Grid>
        </Grid>
    )


}

export default ProjectNews;