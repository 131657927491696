import React from 'react';
import Grid from '@mui/material/Grid2';

import styles from './footer.module.css';
import { Link, Typography } from '@mui/material';
import { isMobile } from 'react-device-detect';


const Footer = () => {

    return (
        <footer className={styles.footerContainer}>
            <Grid className={styles.footer_subcontainer} sx={{ zIndex:1 }}>
                <img src="/assets/images/footer_logo.png" alt="" />
                <Grid className={styles.footer_information}>
                    <Grid sx={{width: {xs:"100%", sm:"50%"}}}>
                        <Typography variant="body2" component={"p"} className={styles.footer_nav}>Simon India is an engineering consulting company that provides expert consultancy, SME, a road map and engineering solutions to organizations that need specialized engineering knowledge for their projects.</Typography>
                    </Grid>
                    <Grid sx={{display: 'flex', justifyContent: isMobile? 'flex-start': "flex-end", width: {xs:"100%", sm:"50%"}}}>
                        <Grid sx={{display: 'flex', alignItems:'end', justifyContent:'flex-end', columnGap: 2}}>
                            <a href="/https://www.linkedin.com/company/simon-india-ltd" target="_blank"><img src='/assets/images/linked_icon.png' style={{marginBottom:0}} alt="linkedin"/></a>
                            <Typography variant='body2' component={"p"} className={styles.footer_social}>Stay<br />Connected</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={styles.footer_address}>
                    <Grid item columns={{xs:12, sm:4}} sx={{width: {xs:"100%", sm:"35%"}}}>
                        <Typography variant='body2' component={"p"} className={styles.footer_title}>Head Office Address</Typography>
                        <Typography variant='body2' component={"p"} className={styles.footer_nav}>
                            Simon India Ltd.
                            <br/>Global Business Park,
                            <br/>Tower A, 8th Floor, Sector-26, 
                            <br/>MG Road, Gurgaon-122002, 
                            <br/>HARYANA, INDIA
                        </Typography>

                        <Typography variant='body2' component={"p"} className={styles.footer_title} sx={{marginTop:'30px'}}>For project queries</Typography>
                        <Typography variant='body2' component={"p"} className={styles.footer_nav}>
                            <Link href="mailto:bd.sil@adventz.com" className={styles.footer_nav}>bd.sil@adventz.com</Link>
                            <br/>Tel : 124- 4518500
                        </Typography>
                        <Grid sx={{display: 'none', alignItems:'end', columnGap: 2, marginTop:'2rem'}}>
                            <Typography variant='body2' component={"p"} className={styles.footer_nav}>Follow us</Typography>
                            <a href="/#"><img src='/assets/images/linked_icon.png' style={{marginBottom:0}} alt="linkedin"/></a>
                        </Grid>
                    </Grid>
                    <Grid sx={{display: 'flex', flexFlow:'column', width: "100%"}}>
                        <Grid sx={{width:{xs: "100%", sm:"70%"}}} className={styles.links_container}>
                            <Grid sx={{width:{xs: "50%", sm:"25%"}}}>
                                <Typography variant='body2' component={"p"} className={styles.footer_title}>Services</Typography>
                                <nav style={{listStyle:'none'}}>
                                    <li><Link href="/expertise" className={`${styles.footer_title} ${styles.nav_opacity}`}>Expertise</Link></li>
                                    <li><Link href="/news" className={`${styles.footer_title} ${styles.nav_opacity}`}>What's new</Link></li>
                                    <li><Link href="/faq" className={`${styles.footer_title} ${styles.nav_opacity}`}>FAQ</Link></li>
                                </nav>
                            </Grid>
                            
                            <Grid sx={{width:{xs: "50%", sm:"25%"}}}>
                                <Typography variant='body2' component={"p"} className={`${styles.footer_title}`}>Know us</Typography>
                                <nav style={{listStyle:'none'}}>
                                    <li><Link href="/about" className={`${styles.footer_link} ${styles.nav_opacity}`}>About us</Link></li>
                                    <li><Link href="/value-preposition" className={`${styles.footer_link} ${styles.nav_opacity}`}>Our value proposition</Link></li>
                                    <li><Link href="/leadership" className={`${styles.footer_link} ${styles.nav_opacity}`}>Leadership team</Link></li>
                                    <li><Link href="/sustainability" className={`${styles.footer_link} ${styles.nav_opacity}`}>Sustanibility</Link></li>
                                </nav>
                            </Grid>
                            
                        </Grid>
                        <Grid sx={{width:{xs: "100%", sm:"70%"}, marginTop:'2rem'}} className={styles.links_container}>
                            <Grid sx={{width:{xs: "50%", sm:"25%"}}}>
                                <Typography variant='body2' component={"p"} className={styles.footer_title}>Join us</Typography>
                                <nav style={{listStyle:'none'}}>
                                    <li><Link href="/our-culture" className={`${styles.footer_link} ${styles.nav_opacity}`}>Our culture</Link></li>
                                    <li><Link href="/supply-partner" className={`${styles.footer_link} ${styles.nav_opacity}`}>Grow together</Link></li>
                                    <li><Link href="/apply" className={`${styles.footer_link} ${styles.nav_opacity}`}>Apply</Link></li>
                                </nav>
                            </Grid>
                            <Grid sx={{width:{xs: "50%", sm:"25%"}}}>
                                <Typography variant='body2' component={"p"} className={styles.footer_title}>Projects</Typography>
                                <nav style={{listStyle:'none'}}>
                                    <li><Link href="/project-gallery" className={`${styles.footer_link} ${styles.nav_opacity}`}>Project gallery</Link></li>
                                    <li><Link href="/project-quality" className={`${styles.footer_link} ${styles.nav_opacity}`}>Quality & safety</Link></li>
                                </nav>
                            </Grid>
                            
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid sx={{marginTop:!isMobile ? "-50px": "0px", width: '100%'}}>
                <img style={{width: '100%'}} src={!isMobile ? "/assets/images/footer_design.png" : "/assets/images/footer_design_mobile.png"} alt="" />
            </Grid>
            <Grid className={styles.footer_subcontainer}>
                <Grid columns={{xs:12}} className={styles.footer_copycontainer} >
                    <Typography variant='body2' component={"p"} className={styles.footer_lower_link}>@simon India 2024</Typography>
                    <div style={{display: 'flex', flexFlow: isMobile? "column":"row", gap:'10px'}}>
                        <Link href="#" className={styles.footer_lower_link}>Terms & Conditions</Link>
                        <Link href="#" className={styles.footer_lower_link}>Privacy Policy</Link>
                    </div>
                    <Link href="/#" className={styles.footer_lower_link}>By HUDE</Link>
                </Grid>
            </Grid>
        </footer>
    )
}

export default Footer;