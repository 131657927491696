import React from 'react';
import Grid from '@mui/material/Grid2';
import { Typography, Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/material';
// import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { isMobile } from 'react-device-detect';

import { } from './valuePreposition.css';
import styles from './valuePreposition.module.css';

const FaqContainer = () =>{

    const handleChange = () => {

    }

    const CustomExpandIcon = () => {
        return (
          <Box
            sx={{
              ".Mui-expanded & > .collapsIconWrapper": {
                display: "none"
              },
              ".expandIconWrapper": {
                display: "none"
              },
              ".Mui-expanded & > .expandIconWrapper": {
                display: "block"
              }
            }}
          >
            <RemoveIcon className={`${styles.accord_icon} expandIconWrapper`} />
            <AddIcon className={`${styles.accord_icon} collapsIconWrapper`} />
          </Box>
        );
      };

    return (
        <Grid className={styles.value_container} sx={{paddingLeft: isMobile ? "18px" : 0, paddingRight: isMobile ? "18px" : 0}}>
            <Grid className={styles.value_subcontainer} sx={{marginTop:'80px', marginBottom:'180px'}}>
                <Typography variant="h3" component={"h3"} className={styles.sub_title} sx={{paddingLeft: isMobile ? "18px" : 0, width:'100%', textAlign: isMobile ?'left':'center', marginBottom: "30px"}}>Frequently Asked Questions</Typography>
                <Grid>
                    <Accordion className={styles.accord} onChange={handleChange("panel1")}>
                        <AccordionSummary
                        expandIcon={ <CustomExpandIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        >
                        <Typography variant='body2' component={"p"} className={styles.accordian_summary}>What makes Simon India Limited different from other companies?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            Simon India Limited differentiates itself in the EPC and EPCM sectors through its deep expertise in the Chemical, Fertilizer, Oil & Gas industries, offering comprehensive solutions across engineering, procurement, construction, and management.With a strong focus on quality, safety, and client satisfaction, Simon India Limited combines a proven track record of successful project delivery with a client-centric approach and robust post-project support. A key aspect of our approach is the integration of assistance from numerous subject matter experts, ensuring that each project benefits from specialized knowledge and cutting-edge innovations.
                          </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className={styles.accord}>
                        <AccordionSummary
                        expandIcon={<AddIcon className={styles.accord_icon} />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                        >
                        <Typography variant='body2' component={"p"} className={styles.accordian_summary}>What is your experience with international projects? Are there any specific continents/ countries that you are looking to explore for more projects?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Typography>
                          Simon India has significant experience in international projects, particularly in the Chemicals, Fertilizer, and Oil & Gas industries. We’ve successfully delivered projects in regions like the Middle East and Southeast Asia, adapting to local regulations. We're also actively exploring emerging markets, aiming to expand our global footprint through innovative solutions and strong industry partnerships.
                        </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className={styles.accord}>
                        <AccordionSummary
                        expandIcon={<AddIcon className={styles.accord_icon} />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                        >
                        <Typography variant='body2' component={"p"} className={styles.accordian_summary}>What strategies do you use to communicate effectively across different time zones and cultures?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Typography>
                        We use several strategies to ensure effective communication across time zones and cultures. Digital tools like video conferencing and collaborative platforms enable real-time interaction. We schedule flexible meetings to accommodate time differences and emphasize cultural sensitivity through training. Clear documentation ensures consistency, and dedicated liaisons manage international communications to streamline coordination and promptly address any concerns.
                        </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className={styles.accord}>
                        <AccordionSummary
                        expandIcon={<AddIcon className={styles.accord_icon} />}
                        aria-controls="panel4-content"
                        id="panel4-header"
                        >
                        <Typography variant='body2' component={"p"} className={styles.accordian_summary}>How do you ensure adherence to international compliance standards?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Typography>
                          We ensure adherence to international compliance standards by conducting thorough risk assessments to address legal and regulatory issues, including international trade laws. Regular audits and reviews are performed throughout the project to maintain compliance. Additionally, we prioritize transparent communication with stakeholders to promptly resolve any legal or contractual concerns, keeping all parties informed and aligned.
                        </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className={styles.accord}>
                        <AccordionSummary
                        expandIcon={<AddIcon className={styles.accord_icon} />}
                        aria-controls="panel5-content"
                        id="panel5-header"
                        >
                        <Typography variant='body2' component={"p"} className={styles.accordian_summary}>How does Simon India Limited help in technology selection?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Typography>
                          Simon India Limited assists in technology selection by thoroughly assessing project needs and evaluating various technologies for performance, cost, and compatibility. We conduct feasibility studies to ensure each option meets practical and economic requirements. By analysing potential risks and aligning technology choices with project goals, we ensure the selected technology optimizes performance and efficiency, setting the stage for successful project execution and delivery.
                        </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>

        </Grid>
    )


}

export default FaqContainer;