import React, {useRef, useState, useEffect} from 'react';
import Grid from '@mui/material/Grid2';
import styles from './header.module.css';
import { isMobile } from 'react-device-detect';
// import {Link} from 'react-router-dom';
import { Link, Popper, Fade, Paper, ClickAwayListener, MenuList } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import MobileHeaderMenu from './mobileHeaderMenu';


const Header = () => {
    const anchor1Ref = useRef(null);
    const anchor2Ref = useRef(null);
    const anchor3Ref = useRef(null);
    const [aboutOpen, setAboutOpen] = useState(false);
    const [projectOpen, setProjectOpen] = useState(false);
    const [joinOpen, setJoinOpen] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(false)
    const prev1Open = useRef(aboutOpen);
    const prev2Open = useRef(projectOpen);
    const prev3Open = useRef(joinOpen);

    const linkStyle={marginRight:'2em', textDecoration:'none'}
    
    useEffect(()=>{
        if (prev1Open.current === true && aboutOpen === false) {
            anchor1Ref.current.focus();
        }
        prev1Open.current = aboutOpen;
    }, [aboutOpen])

    useEffect(()=>{
        if (prev2Open.current === true && projectOpen === false) {
            anchor2Ref.current.focus();
        }
        prev2Open.current = projectOpen;
    }, [projectOpen])

    useEffect(()=>{
        if (prev3Open.current === true && joinOpen === false) {
            anchor3Ref.current.focus();
        }
        prev3Open.current = joinOpen;
    }, [joinOpen])

    const callAboutMenu = () => {
        handleClose()
        setAboutOpen(true);
    }

    const callProjectMenu = () => {
        handleClose()
        setProjectOpen(true);
    }
    const callJoinMenu = () => {
        handleClose()
        setJoinOpen(true);
    }


    const handleClose = () => {
        setAboutOpen(false);
        setProjectOpen(false);
        setJoinOpen(false);
    }

    const aboutPopper = () => 
        <Popper
            open={aboutOpen}
            anchorEl={anchor1Ref.current}
            style={{zIndex:1}}
            role={undefined}
            placement="bottom-end"
            transition
            disablePortal
        >
            {({ TransitionProps, placement }) => (
            <Fade
                {...TransitionProps} timeout={350}
                style={{
                zIndex:1,
                transformOrigin:
                    placement === 'bottom-start' ? 'center top' : 'left bottom',
                }}
            >
                <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                    <Grid item sx={{ width:'100%', maxWidth:'565px', 
                        display:'flex', justifyContent:'flex-start', borderRadius: '5px', 
                        padding:'2em 1em', backgroundColor: "#ECF2F6"}}
                        autoFocusItem={aboutOpen}
                        id="compositionabout-menu"
                        aria-labelledby="composition-button"
                    >
                        <Grid item xs={6} sx={{display:'flex', alignItems:'center', padding:'0em'}}>
                            <img src='/assets/images/know_menu.png' alt='menubg' style={{width:'222px'}} />
                        </Grid>
                        <Grid item xs={5} sx={{}}>
                            <MenuList>
                                <li style={{textAlign:'left', padding:'0.35em 0px', borderRight:'none'}}>
                                    <Link href='/about' className= {styles.pop_link} data-text="About us" style={{...linkStyle, marginRight:'0em !important'}} onClick={handleClose}>About us</Link>
                                </li>
                                <li style={{textAlign:'left', padding:'0.35em 0px', borderRight:'none'}}>
                                    <Link href='/value-preposition' className= {styles.pop_link} data-text="Our value proposition" style={{...linkStyle, marginRight:'0em !important'}} onClick={handleClose}>Our value proposition</Link>
                                </li>
                                <li style={{textAlign:'left', padding:'0.35em 0px', borderRight:'none'}}>
                                    <Link href='/leadership' className= {styles.pop_link} data-text="Leadership team" style={{...linkStyle, marginRight:'0em !important'}} onClick={handleClose}>Leadership team</Link>
                                </li>
                                <li style={{textAlign:'left', padding:'0.35em 0px', borderRight:'none'}}>
                                    <Link href='/sustainability' className= {styles.pop_link} data-text="Sustainability" style={{...linkStyle, marginRight:'0em !important'}} onClick={handleClose}>Sustainability                                    </Link>
                                </li>
                                
                            </MenuList>
                        </Grid>
                    </Grid>
                    
                </ClickAwayListener>
                </Paper>
            </Fade>
            )}
        </Popper>

const projectPopper = () => 
    <Popper
        open={projectOpen}
        anchorEl={anchor2Ref.current}
        style={{zIndex:1}}
        role={undefined}
        placement="bottom-end"
        transition
        disablePortal
    >
        {({ TransitionProps, placement }) => (
        <Fade
            {...TransitionProps} timeout={350}
            style={{
            zIndex:1,
            transformOrigin:
                placement === 'bottom-start' ? 'center top' : 'left bottom',
            }}
        >
            <Paper>
            <ClickAwayListener onClickAway={handleClose}>
                <Grid item sx={{ width:'100%', maxWidth:'565px', 
                    display:'flex', justifyContent:'flex-start', borderRadius: '5px', 
                    padding:'2em 1em', backgroundColor: "#ECF2F6"}}
                    autoFocusItem={projectOpen}
                    id="compositionproject-menu"
                    aria-labelledby="composition-button"
                >
                    <Grid item xs={6} sx={{display:'flex', alignItems:'center', padding:'0px'}}>
                        <img src='/assets/images/projects_menu.png' alt='menubg' style={{width:'222px'}} />
                    </Grid>
                    <Grid item xs={5} sx={{}}>
                        <MenuList>
                            <li style={{textAlign:'left', padding:'0.35em 0px', borderRight:'none'}}>
                                <Link href='/project-gallery' className= {styles.pop_link} data-text="Project gallery" style={{...linkStyle, marginRight:'0em !important'}} onClick={handleClose}>Project gallery</Link>
                            </li>
                            <li style={{textAlign:'left', padding:'0.35em 0px', borderRight:'none'}}>
                                <Link href='/project-quality' className= {styles.pop_link} data-text="Quality & safety" style={{...linkStyle, marginRight:'0em !important'}} onClick={handleClose}>Quality & safety</Link>
                            </li>
                        </MenuList>
                    </Grid>
                </Grid>
                
            </ClickAwayListener>
            </Paper>
        </Fade>
        )}
    </Popper>

const joinPopper = () => 
    <Popper
        open={joinOpen}
        anchorEl={anchor3Ref.current}
        style={{zIndex:1}}
        role={undefined}
        placement="bottom-end"
        transition
        disablePortal
    >
        {({ TransitionProps, placement }) => (
        <Fade
            {...TransitionProps} timeout={350}
            style={{
            zIndex:1,
            transformOrigin:
                placement === 'bottom-start' ? 'center top' : 'left bottom',
            }}
        >
            <Paper>
            <ClickAwayListener onClickAway={handleClose}>
                <Grid item sx={{ width:'100%', maxWidth:'565px', 
                    display:'flex', justifyContent:'flex-start', borderRadius: '5px', 
                    padding:'2em 1em', backgroundColor: "#ECF2F6"}}
                    autoFocusItem={aboutOpen}
                    id="compositionjoin-menu"
                    aria-labelledby="composition-button"
                >
                    <Grid item xs={6} sx={{display:'flex', alignItems:'center', padding:'0px'}}>
                        <img src='/assets/images/join_menu.png' alt='menubg' style={{width:'222px'}} />
                    </Grid>
                    <Grid item xs={5} sx={{}}>
                        <MenuList>
                            <li style={{textAlign:'left', padding:'0.35em 0px', borderRight:'none'}}>
                                <Link href='/our-culture' className= {styles.pop_link} data-text="Our culture"style={{...linkStyle, marginRight:'0em !important'}} onClick={handleClose}>Our culture</Link>
                            </li>
                            <li style={{textAlign:'left', padding:'0.35em 0px', borderRight:'none'}}>
                                <Link href='/supply-partner' className= {styles.pop_link} data-text="Grow together" style={{...linkStyle, marginRight:'0em !important'}} onClick={handleClose}>Grow together</Link>
                            </li>
                            <li style={{textAlign:'left', padding:'0.35em 0px', borderRight:'none'}}>
                                <Link href='/apply' className= {styles.pop_link} data-text="Apply" style={{...linkStyle, marginRight:'0em !important'}} onClick={handleClose}>Apply</Link>
                            </li>
                        </MenuList>
                    </Grid>
                </Grid>
                
            </ClickAwayListener>
            </Paper>
        </Fade>
        )}
    </Popper>

    
    const handleScroll = () => {
        const position = window.scrollY;
        if(position > 70){
        setScrollPosition(true)
        } else {
        setScrollPosition(false);
        }
    }

    useEffect(()=> {
            window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [])



    return (
        <header className= {styles.header}>
            <Grid container className= {styles.header_container} 
                sx={{visibility: scrollPosition && isMobile ? "hidden" : "visible",  marginTop: scrollPosition && isMobile ? 0: "18px"}}
            >
                <Grid className={styles.header_subcontainer} 
                    columns={{xs: 12, sm:12}}
                    sx={{
                        backgroundColor: scrollPosition && isMobile ? "rgba(0, -0, 0, 0.5)": "rgba(0, -0, 0, 0)"}}
                >
                    <a style={{marginBottom:"-4px"}} href='/'>
                        <img src="/assets/images/logo.png" alt="logo" className={styles.header_logo}  style={{borderRadius:"5px 0px 0px 5px"}} />
                    </a>
                    {isMobile ? (
                        <MobileHeaderMenu />
                    ):
                    <ul className={styles.nav}>
                    <li>
                        <Link href="/services" data-text="Services" onMouseOver={handleClose} className={styles.nonpop_hover}>Services</Link>
                    </li>
                    <li><Link href="/expertise" data-text="Expertise" onMouseOver={handleClose} className={styles.nonpop_hover}>Expertise</Link></li>
                    <li className={styles.headerMenu}>
                        <Link href="#"
                        ref={anchor2Ref}
                        aria-controls={projectOpen ? 'compositionproject-menu' : undefined}
                        aria-expanded={projectOpen ? 'true' : undefined}
                        aria-haspopup="true"
                        data-text="Projects"
                        onMouseOver={callProjectMenu}
                        ><span className={styles.pop_menu}> Projects <ExpandMoreIcon style={{verticalAlign:'middle', transform: !projectOpen ? 'rotate(0deg)': 'rotate(180deg)' , marginTop:0}} ></ExpandMoreIcon></span></Link>
                        </li>
                        {projectPopper()}
                    <li className={styles.headerMenu}>
                        <Link 
                        ref={anchor1Ref}
                        aria-controls={aboutOpen ? 'compositionabout-menu' : undefined}
                        aria-expanded={aboutOpen ? 'true' : undefined}
                        aria-haspopup="true"
                        data-text="Know us"
                        onMouseOver={callAboutMenu} 
                        href="/#"><span className={styles.pop_menu}> Know us <ExpandMoreIcon style={{verticalAlign:'middle', transform: !aboutOpen ? 'rotate(0deg)': 'rotate(180deg)' , marginTop:0}} ></ExpandMoreIcon></span></Link></li>
                        {aboutPopper()}
                    <li><Link href="/news"data-text="What's new" onMouseOver={handleClose} className={styles.nonpop_hover}>What's new</Link></li>
                    {/* <li><Link to="#">FAQ</Link></li> */}
                    <li>
                        <Link 
                            ref={anchor3Ref}
                            aria-controls={joinOpen ? 'compositionjoin-menu' : undefined}
                            aria-expanded={joinOpen ? 'true' : undefined}
                            aria-haspopup="true"
                            data-text="Join us"
                            onMouseOver={callJoinMenu} 
                            href="/#"
                        ><span className={styles.pop_menu}>Join us <ExpandMoreIcon style={{verticalAlign:'middle', transform: !aboutOpen ? 'rotate(0deg)': 'rotate(180deg)' , marginTop:0}} ></ExpandMoreIcon></span></Link>
                    </li>
                    {joinPopper()}
                    </ul>
                    }
                </Grid>
            </Grid>

        </header>
    )
}

export default Header;