import React from 'react';

import Grid from '@mui/material/Grid2';
import { Typography, Card, CardContent, CardMedia, Button } from '@mui/material';

import styles from './whatsnew.module.css'
import { ArrowForward } from '@mui/icons-material';
import { isMobile } from 'react-device-detect';



const News = () => {

    return (
        <Grid className={styles.whatsnew_container} sx={{paddingBottom: "100px"}}>
            <Grid className={styles.whatsnew_subcontainer}>
                <Typography variant='h3' component={"h3"} className={styles.whatsnew_title} 
                    sx={{marginBottom:"3rem", paddingLeft: isMobile? "18px" : "0px", paddingRight: isMobile? "18px" : "0px" }}>Updates</Typography>
                <Grid className={styles.cardContainer}>
                    <Card sx={{ maxWidth: 336, boxShadow: 'none' }}>
                        <CardMedia 
                        sx={{ height: 216, marginBottom:1 }}
                        image="/assets/images/news1.png"
                        title="news1"
                        />
                        <CardContent className={styles.cardContent}>
                        <Typography gutterBottom variant="h5" component="div" className={styles.newscardtitle}>
                            Simon India Limited Secures Major Contract with Paradeep Phosphates Limited
                        </Typography>
                        <Typography variant="body2" className={styles.newscard_content}>
                            July 26, 2024
                        </Typography>
                        </CardContent>
                    </Card>
                    <Card sx={{ maxWidth: 336, boxShadow: 'none' }}>
                        <CardMedia 
                        sx={{ height: 216, marginBottom:1 }}
                        image="/assets/images/news2.png"
                        title="news1"
                        />
                        <CardContent className={styles.cardContent} sx={{backgroundColor: "#ECF2F7"}}>
                        <Typography gutterBottom variant="h5" component="div" className={`${styles.newscardtitle} ${styles.newscard_tcolor}`}>
                            Simon India Team Gains Insight in Procurement with Expert-Led Session
                        </Typography>
                        <Typography variant="body2" className={`${styles.newscard_content} ${styles.newscard_ccolor}`}>
                            July 26, 2024
                        </Typography>
                        </CardContent>
                    </Card>
                    <Card sx={{ maxWidth: 336, boxShadow: 'none' }}>
                        <CardMedia 
                        sx={{ height: 216, marginBottom:1 }}
                        image="/assets/images/news3.png"
                        title="news1"
                        />
                        <CardContent sx={{backgroundColor: "#ECF2F7"}} className={styles.cardContent}>
                        <Typography gutterBottom variant="h5" component="div" className={`${styles.newscardtitle} ${styles.newscard_tcolor}`}>
                            Simon India Participates in New Delhi Roundtable on Hydrogen Technology
                        </Typography>
                        <Typography variant="body2" className={`${styles.newscard_content} ${styles.newscard_ccolor}`}>
                            July 26, 2024
                        </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid sx={{width:"100%", display:"flex", justifyContent: 'flex-end', marginTop: '30px'}}>
                    <Button variant='text' className={styles.newsButton} endIcon={<ArrowForward />}>View all</Button>
                </Grid>
            </Grid>

        </Grid>
    )

}

export default News;