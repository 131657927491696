import React from 'react';
import Grid from '@mui/material/Grid2';
import PageBanner from '../../components/pageBanner';
import AboutStrength from './aboutStrength';
import AboutBrand from './aboutBrand'
import AboutExplore from './aboutExplore';
import AboutCustomer from './aboutCustomer';
import AboutStory from './aboutStory';
import AboutGroup from './aboutGroup';
import AdvantageC from '../../components/advantageC';
import PageContent from '../../components/pageContent';

const AboutUs = () => {
    const bannerPath = [
        {title: 'Home', url: '/'},
        {title: 'Know us', url: '/'},
        {title: 'About us', url: ''}
    ]


    return (
        <Grid sx={{display: 'flex', flexFlow:'column', width: '100%'}}>
            <PageBanner title="About Us" bannerimage="about_banner.jpg" bannerPath={bannerPath} />
            <PageContent details="Simon India specializes in EPC, EPCM, and PMC services for process plants, chemicals, fertilizers, oil & gas, petrochemicals, and cement projects using clean technologies. 
                        <br/><br/>
                        Part of the Adventz Group, we have delivered 75+ major projects over 27 years, fostering customer satisfaction, employee motivation, and innovation. Our expertise spans critical sectors, providing tech-enabled solutions that drive efficiency and growth. 
                        <br/><br/>
                        Our commitment to quality and safety is unwavering, reflected in our adherence to international standards and best practices. We leverage advanced engineering techniques and innovative project management methodologies to deliver superior results. Our dedicated team of professionals, equipped with extensive industry knowledge, deep engineering expertise and innovative approach to providing solutions ensures that every project meets the highest standards of excellence. 
                        <br/><br/>
                        Join us in building a sustainable and prosperous future."  />
            <AboutStrength />
            <AboutBrand />
            <AboutExplore />
            <AboutCustomer />
            <AboutStory />
            <AboutGroup />
            <AdvantageC />
        </Grid>
    )

}

export default AboutUs