import React, { useState } from 'react';
import { useLocation , useNavigate} from 'react-router-dom';
import Grid from '@mui/material/Grid2';
import Axios from 'axios';
import { Typography, Button  } from '@mui/material';

import styles from './supplyForm.module.css';
// import { } from './supplyForm.css';
import { East } from '@mui/icons-material';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { isMobile } from 'react-device-detect';
import { validateEmail } from '../../../utils/util';


const SupplyForm = () => {

    const navigate = useNavigate();
    const location = useLocation();
    // const {name} = useParams();
    // const [requirement, setRequirement] = useState(-1);
    const [validate, setValidate] = useState({
        bool: false,
        msg: ''
    });
    const pathPart = location.pathname.split("/");
    const navurl = pathPart[pathPart.length-1];
    const [status, setStatus] = useState(false);

    const [userInform, setUserInform] = useState({
        fname: '',
        lname:'',
        usermail: '',
        designation: '',
        userstate: '',
        usercountry: '',
        companyname:'',
        compsite: '',
        compregnumber: '',
        compdesc:'',
        compyears: '',
        compemployee: '',
        compprimaryfocus: '',
        positionapplied: '',
    })
   
    // const mainStyle = {px: !isMobile ? 10 : 2.5, width:'100%', maxWidth:1440, display:'flex', justifyContent:'center', pt:'2em'}
    const inputStyle = {
        fontFamily:'Kumbh Sans !important',
        fontSize:'1em',
        color:'#313131 !important',
        backgroundColor:'#FFF',
        border:'1px solid #CACACA',
        borderRadius:'5px',
        height:'1rem',
        width:'100%',
        padding: '1em',
        marginBottom: '1.5em'
    }

      const handleSubmit = (event) => {
        event.preventDefault();
        const { fname, lname, usermail, designation, userstate, usercountry, companyname, compsite, compregnumber, compdesc, compyears, compemployee, compprimaryfocus, positionapplied } = userInform;
        if(fname.trim() === '' ) { setValidate({bool:true, msg:'Write first name.'});  return; }
        if(lname.trim() === '' ) { setValidate({bool:true, msg:'Write last name.'});  return; }
        if(designation.trim() === '') {setValidate({bool:true, msg:'Write correct designation.'}); return;} 
        if(userstate.trim() === '' || !validateEmail(usermail) ) {setValidate({bool:true, msg:'Write correct state/province.'}); return;}
        if(usercountry.trim() === '' ) {setValidate({bool:true, msg:'Write correct country name.'}); return;}
        // if(careaexp.trim() === '' ) {setValidate({bool:true, msg:'Write core experience.'}); return;}
        // if(totalexp.trim() === '' ) {setValidate({bool:true, msg:'Write total experience.'}); return;}
        // if(positionapplied.trim() === '' ) {setValidate({bool:true, msg:'Write position applied.'}); return;}

        Axios.post('/supplymail.php', {name: `${fname} ${lname}`, email:usermail, designation, userstate, usercountry, companyname, compsite, compregnumber, compdesc, compyears, compemployee, compprimaryfocus, positionapplied})
        .then(res => console.log('res ', res))
        .catch(error => console.log('error ', error))
        


        setStatus(true)



      }
      const callUser = (event) =>{
        event.preventDefault();
        setStatus(false);
        const {id, value} = event.target;
        const tempUserInform = {...userInform};
        tempUserInform[id] = value
        setUserInform(tempUserInform);
    }


    return (
        <Grid className= {styles.supplyForm_container} 
            sx={{paddingTop:"0px", paddingBottom:"120px", flexFlow:"column", alignItems:'center'}}>
            <Grid className={styles.supplyForm_tabcontainer} sx={{borderBottom:"1px solid #CEE86B"}}>
                    <img src= {isMobile? "/assets/images/supply_tab1_mobile.png" : "/assets/images/supply_tab1.jpg"} alt="" />
                    <Grid sx={{backgroundColor:"#ECF2F6", padding: !isMobile? "4rem 7rem 0rem": "4rem 0rem 0rem"}} >
                        <Grid sx={{marginBottom:"2rem",paddingLeft: isMobile? "18px":"0px", paddingRight: isMobile? "18px":"0px" }}>
                            <Typography variant="h3" component={"h3"} className={styles.supplyForm_title}>
                                Become a Supply Partner
                            </Typography>
                        </Grid>
                        <Grid className={styles.supplyForm_subcontainer} 
                            sx={{display:"flex", flexFlow: isMobile?"column-reverse":"row", gap:"2rem", paddingLeft: isMobile? "18px":"0px", paddingRight: isMobile? "18px":"0px" }}>
                            <Grid sx={{width:{xs:"100%", sm:"50%"}}}>
                                <Typography variant="body2" component={"p"} className={styles.supplyForm_content}>
                                    Partner with Simon India as a trusted supply partner and gain access to long-term opportunities in large-scale projects across industries like chemicals, oil & gas, and renewable energy. As a valued supply partner, you’ll benefit from consistent work, collaborative growth, and strong business relationships. We prioritize quality, reliability, and timely delivery, ensuring your contributions are integral to the success of every project. Join us in delivering world-class solutions and driving innovation across industries.
                                </Typography>
                            </Grid>
                            <Grid sx={{width:{xs:"100%", sm:"50%", display:"flex", justifyContent:"center", alignItems:"center"}}}>
                                <img src="/assets/images/supplytab1_icon.png" alt=""/>
                            </Grid>
                        </Grid>
                        <Grid sx={{position:"relative", width:"100%", height:"100px", display:"flex", justifyContent:"center"}}>
                            <Button className={styles.provide_btn} endIcon={<KeyboardDoubleArrowDownIcon />} onClick={()=> {navigate("#about-form")}}>Provide the information</Button>
                        </Grid>
                        
                    </Grid>
            </Grid>
            <Grid id="about-form" name="about-form" className={styles.supplyForm_subcontainer} 
                sx={{marginTop:"120px", paddingLeft: isMobile? "18px":"0px", paddingRight: isMobile? "18px":"0px" }}>
                <Typography variant="h3" component={"h3"} className={styles.supplyForm_title}>
                    About Your Organization
                </Typography>
                <Grid sx={{marginTop:"2rem"}}>
                <form>
                    { !status && <>
                        <Grid sx={{width:{xs:"90%", sm:"70%"}}}>
                            <Grid>
                                <label htmlFor='compname' className={styles.supplyForm_content}>Company name:</label>
                                <input type='text' id='compname' name='compname' value={userInform.companyname} placeholder='' 
                                    onChange={callUser} style={inputStyle} />
                            </Grid>
                            <Grid>
                                <label htmlFor='compsite' className={styles.supplyForm_content}>Company website:</label>
                                <input type='text' id='compsite' name='compsite' value={userInform.compsite} placeholder='' 
                                    onChange={callUser} style={inputStyle} />
                            </Grid>
                            <Grid>
                                <label htmlFor='compregnumber' className={styles.supplyForm_content}>Company registration number:</label>
                                <input type='text' id='compregnumber' name='compregnumber' value={userInform.compregnumber} placeholder='' 
                                    onChange={callUser} style={inputStyle} />
                            </Grid>
                            <Grid>
                                <label htmlFor='compdesc' className={styles.supplyForm_content}>Comp description:</label>
                                <input type='text' id='compdesc' name='compdesc' value={userInform.compdesc} placeholder='' 
                                    onChange={callUser} style={inputStyle} />
                            </Grid>
                            <Grid>
                                <label htmlFor='compyears' className={styles.supplyForm_content}>Years in business:</label>
                                <input type='text' id='compyears' name='compyears' value={userInform.compyears} placeholder='' 
                                    onChange={callUser} style={inputStyle} />
                            </Grid>
                            <Grid>
                                <label htmlFor='compemployee' className={styles.supplyForm_content}>Number of employee:</label>
                                <input type='text' id='compemployee' name='compemployee' value={userInform.compemployee} placeholder='' 
                                    onChange={callUser} style={inputStyle} />
                            </Grid>
                            <Grid>
                                <label htmlFor='compprimaryfocus' className={styles.supplyForm_content}>Primary industry focus:</label>
                                <input type='text' id='compprimaryfocus' name='compprimaryfocus' value={userInform.compprimaryfocus} placeholder='' 
                                    onChange={callUser} style={inputStyle} />
                            </Grid>
                            <Grid>
                                <label htmlFor='positionapplied' className={styles.supplyForm_content}>Position applying for:</label>
                                <input type='text' id='positionapplied' name='positionapplied' value={userInform.positionapplied} placeholder='' 
                                    onChange={callUser} style={inputStyle} />
                            </Grid>
                            <Typography variant="h3" component={"h3"} className={styles.supplyForm_title} sx={{marginTop:"3rem", marginBottom:"2rem"}}>
                                About you
                            </Typography>
                            <Grid>
                                <label htmlFor='fname' className={styles.supplyForm_content}>First name:</label>
                                <input type='text' id='fname' name='fname' value={userInform.fname} placeholder='' 
                                    onChange={callUser} style={inputStyle} />
                            </Grid>
                            <Grid>
                                <label htmlFor='lname' className={styles.supplyForm_content}>Last name:</label>
                                <input type='text' id='lname' name='lname' value={userInform.lname} placeholder='' 
                                    onChange={callUser} style={inputStyle} />
                            </Grid>
                            <Grid>
                                <label htmlFor='designation' className={styles.supplyForm_content}>Designation/ Role:</label>
                                <input type='text' id='designation' name='designation' value={userInform.designation} placeholder='' 
                                    onChange={callUser} style={inputStyle} />
                            </Grid>
                            <Grid>
                                <label htmlFor='usermail' className={styles.supplyForm_content}>Email:</label>
                                <input type='text' id='usermail' name='usermail' value={userInform.usermail} placeholder='' 
                                    onChange={callUser} style={inputStyle} />
                            </Grid>
                            <Grid>
                                <label htmlFor='userstate' className={styles.supplyForm_content}>State/Province:</label>
                                <input type='text' id='userstate' name='userstate' value={userInform.userstate} placeholder='' 
                                    onChange={callUser} style={inputStyle} />
                            </Grid>
                            <Grid>
                                <label htmlFor='usercountry' className={styles.supplyForm_content}>Country:</label>
                                <input type='text' id='usercountry' name='usercountry' value={userInform.usercountry} placeholder='' 
                                    onChange={callUser} style={inputStyle} />
                            </Grid>
                        </Grid>
                        </> 
                    }
                        {validate.bool && !status && <Typography variant='body1' component={'p'} className='normal-text text-color' sx={{py:1}} >{validate.msg}</Typography>}
                        {status && <Typography variant='body2' component={'h4'} className='normal-text text-color' sx={{fontSize:'1.5 !important'}} >Thank you, your reume has been sent. We will be in touch shortly.</Typography>}
                        {!status && <Grid item sx={{display:'flex', columnGap:'0.5em', alignItems:'center', justifyContent:'flex-start', mt:'2em', width:'100%'}}>
                            <Button variant='contained' 
                                className= {styles.supplyForm_btn}
                                endIcon={<East color="#FFFFFF" />}
                                onClick={handleSubmit}
                            >Submit form</Button>
                        </Grid>
                    }
                        
                    </form>
                </Grid>

            </Grid>
        </Grid>
    )

}

export default SupplyForm;