import React, { useState } from 'react';
import Grid from '@mui/material/Grid2';
import { Typography, Card, CardContent, CardMedia } from '@mui/material';
import Slider from 'react-slick';
import { East, West } from '@mui/icons-material';



import styles from './expertise.module.css'
import { isMobile } from 'react-device-detect';


const ExpertiseArea = () => {


    const [serviceIndex, setServiceIndex] = useState(0);

    const NextArrow = props => {
        const { className, style, onClick } = props;
        return <East
        className={className}
        style={{ ...style, display: "block", right:"-1%", background: "transparent", color: "rgba(33, 130, 255, 1)" }}
        onClick={onClick}
      />
    }
    const PrevArrow = props => {
        const { className, style, onClick } = props;
        return <West
        className={className}
        style={{ ...style, display: "block", left:"-1%", background: "transparent", color: "rgba(33, 130, 255, 1)" }}
        onClick={onClick}
      />
    }

    const settings = {
        dots: false,
        arrows: false,
        dotsClass: 'slick-dots',
        className: 'center',
        centerMode: false,
        infinite: false,
        centerPadding: '20px',
        slidesToShow: 4,
        
        slidesToScroll: Math.min(1),
        swipeToSlide: false,
        speed: 500,
        responsive: [
            { 
            breakpoint: 1025,
                setting: {

                }
            },
            {
                breakpoint: 450,
                settings:{
                    arrows: false,
                    dots: true,
                    infinite: false,
                    centerMode: true,
                    swipeToSlide: true,
                    slidesToShow: 1,
                    afterChange: (e) => setServiceIndex(e),
                    nextArrow: <NextArrow />,
                    prevArrow: <PrevArrow />,
                }
            }
        ]
    }

    const expertDetails = [
        {
            id: 0,
            title: "Delivering fertilizing solutions to the world",
            img:"expert_fertilizer.png",
            content: `Delivering world-class fertilizer solutions, Simon India develops state-of-the-art production plants for urea, ammonia, DAP, and NPK fertilizers. We focus on maximizing output while minimizing environmental impact, contributing to sustainable agriculture worldwide. Our advanced designs incorporate resource-efficient methods, significantly reducing energy and water consumption, while improving plant efficiency. 
<br/><br/>
We implement the latest technologies to enhance production capacity, ensuring long-term operational stability and flexibility. Simon India’s expertise extends to reducing emissions, enhancing safety protocols, and ensuring compliance with global environmental standards. 
<br/><br/>
Our tailor-made fertilizer plants are built to optimize productivity and reliability, supporting the global shift toward sustainable farming practices. Through strategic project management and innovative engineering, we ensure that our fertilizer solutions address both immediate and future agricultural demands, making us a trusted partner in the fertilizer industry.
`,
            
        },
        {
            id: 0,
            title: "Advancing Chemical Manufacturing Excellence",
            img: "expert_chemical.png",
            content: `Simon India excels in building highly efficient facilities that produce essential chemicals such as caustic soda, chlorine, and sulfuric acid. Our expertise spans multiple industries, including water treatment, pharmaceuticals, and manufacturing, helping clients optimize chemical production with advanced technologies. We focus on designing chemical plants that ensure operational safety, reduce environmental risks, and enhance energy efficiency. 
<br/><br/>
Our projects are built with strict adherence to international safety and environmental standards, minimizing emissions and promoting sustainable practices. We work closely with clients to deliver cost-effective, scalable solutions that meet specific requirements and regulatory demands. 
<br/><br/>
With our process automation capabilities, Simon India ensures seamless integration of cutting-edge technologies, enhancing both production efficiency and worker safety. Whether it’s building new facilities or upgrading existing ones, our commitment to excellence in chemical manufacturing sets us apart in the industry.
`
        },
        {
            id: 0,
            title: "Optimizing Oil & Gas Operations",
            img: "expert_oil.png",
            content: `Simon India provides turnkey EPCM solutions for the refining and gas processing sectors, offering comprehensive services from initial design to full-scale execution. We are recognized for delivering operational excellence through our in-depth understanding of the oil and gas industry, enabling clients to optimize performance and enhance safety. 
<br/><br/>
Our projects prioritize compliance with global safety and environmental regulations, incorporating sustainable practices to reduce emissions and energy consumption. We leverage advanced technologies, including process automation and digital monitoring systems, to ensure efficient operations and timely project delivery. 
<br/><br/>
Our tailored approach includes plant upgrades, capacity expansions, and strategic management to meet the evolving needs of the industry. Simon India also provides ongoing support, ensuring that clients’ facilities continue to operate at optimal performance levels. Our deep expertise enables us to handle complex challenges while delivering reliable, scalable solutions for oil and gas operations.`,
        },
        {
            id: 0,
            title: "Pioneering Renewable Energy Solutions",
            content: `Simon India is at the forefront of advancing renewable energy infrastructure, specializing in designing and constructing plants that harness sustainable energy sources like solar, wind, and bioenergy. We are committed to reducing dependency on fossil fuels while contributing to a greener, cleaner future. Our renewable energy projects are designed to maximize energy output while minimizing environmental impact, making them both efficient and sustainable. 
<br /><br />
Simon India integrates cutting-edge technologies such as energy storage solutions and smart grid systems to ensure stable and reliable power distribution. Our approach focuses on creating scalable, long-term solutions that align with global sustainability goals, helping clients reduce carbon footprints and lower operational costs. 
<br /><br />
We work closely with our partners to deliver custom-designed renewable energy systems that meet their unique needs, ensuring energy security and supporting the global transition to renewable sources.
`,
            img: "expert_energy.png"
        }
    ]

    const serviceData = [
        <>
            <Card className={styles.expert_card} onClick={() => setServiceIndex(0)}>
                <CardMedia
                    sx={{borderRadius:"5px 5px 0px 0px", height: 155}} 
                    title=""
                    className= {serviceIndex !== 0 ? styles.apply_filter : styles.disable_filter}
                    image='/assets/images/expertise1_thumb.png'
                />
                <CardContent className={styles.expert_card_content}
                    sx={{backgroundColor: serviceIndex === 0 ? "#CEE86B": "#FFF"}}>
                    <Grid className={styles.expert_card_line} sx={{ backgroundColor: serviceIndex === 0 ? "#00258A": "#c1c1c1"}} />
                    <Typography variant='h3' component={'h3'} className={styles.expert_title} sx={{color: serviceIndex === 0 ? "#00258A" : "#5A5A5A" }}>Fertilizers</Typography>
                </CardContent>
            </Card>
        </>,
        <>
            <Card className={styles.expert_card} onClick={() => setServiceIndex(1)}>
                <CardMedia
                    sx={{borderRadius:"5px 5px 0px 0px", height: 155}} 
                    title=""
                    className= {serviceIndex !== 1 ? styles.apply_filter : styles.disable_filter}
                    image='/assets/images/expertise2_thumb.png'
                />
                <CardContent className={styles.expert_card_content}
                    sx={{ backgroundColor: serviceIndex === 1 ? "#CEE86B": "#FFF"}}>
                    <Grid className={styles.expert_card_line} sx={{ backgroundColor: serviceIndex === 1 ? "#00258A": "#c1c1c1"}} />
                    <Typography variant='h3' component={'h3'} className={styles.expert_title} sx={{color: serviceIndex === 1 ? "#00258A" : "#5A5A5A" }}>Chemicals</Typography>
                </CardContent>
            </Card>
        </>,
        <>
            <Card className={styles.expert_card} onClick={() => setServiceIndex(2)}>
                <CardMedia
                    sx={{borderRadius:"5px 5px 0px 0px", height: 155}} 
                    title=""
                    className= {serviceIndex !== 2 ? styles.apply_filter : styles.disable_filter}
                    image='/assets/images/expertise3_thumb.png'
                />
                <CardContent className={styles.expert_card_content}
                    sx={{ backgroundColor: serviceIndex === 1 ? "#CEE86B": "#FFF"}}>
                    <Grid className={styles.expert_card_line} sx={{ backgroundColor: serviceIndex === 2 ? "#00258A": "#c1c1c1"}} />
                    <Typography variant='h4' component={'h4'} className={styles.expert_title} sx={{color: serviceIndex === 2 ? "#00258A" : "#5A5A5A" }}>Oil & Gas </Typography>
                </CardContent>
            </Card>
        </>,
        <>
            <Card className={styles.expert_card}  onClick={() => setServiceIndex(3)}>
                <CardMedia
                    sx={{borderRadius:"5px 5px 0px 0px", height: 155}} 
                    title=""
                    className= {serviceIndex !== 3 ? styles.apply_filter : styles.disable_filter}
                    image='/assets/images/expertise4_thumb.png'
                />
                <CardContent className={styles.expert_card_content}
                    sx={{ backgroundColor: serviceIndex === 1 ? "#CEE86B": "#FFF"}}>
                    <Grid className={styles.expert_card_line} sx={{ backgroundColor: serviceIndex === 3 ? "#00258A": "#c1c1c1"}} />
                    <Typography variant='h4' component={'h4'} className={styles.expert_title} sx={{color: serviceIndex === 3 ? "#00258A" : "#5A5A5A" }}>Renewable Energy </Typography>
                </CardContent>
            </Card>
        </>
    ]




    return (
        <>
        <Grid className={styles.expertise_container} 
            sx={{paddingTop:"80px", paddingBottom: "100px", paddingLeft: isMobile? "36px": "0px", paddingRight: isMobile? "36px": "0px"}}>
            <Grid className={`${styles.expertise_subcontainer} ${styles.expertise_card}`} 
                >
                <Typography variant="h3" component={"h3"} className={styles.expertise_title} sx={{width:{xs:"100%", sm:"30%", marginTop: "-4rem"}, textAlign:'left', marginBottom: "30px"}}>Areas of Expertise</Typography>
                <Grid sx={{width:{xs:"100%", sm:"70%"}}}>
                    <Slider {...settings}>
                        {serviceData}
                    </Slider>
                </Grid>
            </Grid>
        </Grid>
        <Grid className={`${styles.expertise_container} ${styles.expertise_area}`} >
            <Grid className={`${styles.expertise_subcontainer} ${styles.expertise_subarea}`}>
                <img src= {`/assets/images/${expertDetails[serviceIndex].img}`} alt="" />
                <Typography variant="h2" component={"h2"} className={styles.expertise_card_title} sx={{marginTop:"2rem", width:'100%', marginBottom: "30px"}}>
                    {expertDetails[serviceIndex].title}
                </Typography>
                <Grid sx={{display: 'flex', width: '100%', gap: '30px'}}>
                    <Grid sx={{width: {xs:'100%'}}}>
                        <Typography variant="body2" component={"p"} className={styles.expertise_content} 
                        dangerouslySetInnerHTML={{__html: expertDetails[serviceIndex].content}} />
                        
                        
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        </>
)
}
export default ExpertiseArea;