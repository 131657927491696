import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Grid from '@mui/material/Grid2';
import { Typography, Button, styled  } from '@mui/material';

import Axios from 'axios';
import styles from './applyForm.module.css';
// import { } from './applyForm.css';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { East } from '@mui/icons-material';
import { validateEmail } from '../../../utils/util';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });
const fileTypes = ['.docs', '.docx', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',  '.rtf', "application/pdf"]




const ApplyForm = () => {

    const location = useLocation();
    // const {name} = useParams();
    // const [requirement, setRequirement] = useState(-1);
    const [validate, setValidate] = useState({
        bool: false,
        msg: ''
    });
    const pathPart = location.pathname.split("/");
    const navurl = pathPart[pathPart.length-1];
    const [status, setStatus] = useState(false);
    const [file, setFile] = useState(null);

    const [userInform, setUserInform] = useState({
        fname: '',
        lname:'',
        usermail: '',
        compdesc: '',
        userphone: '',
        careaexp: '',
        totalexp:'',
        positionapplied: '',
        userattached: null
    })

    
    // useEffect (() => {
    //     const filteredData = requireDetailsArr.find(item => item.id === name);
    //     // console.log(filteredData);
    //     if(filteredData !== undefined){
    //         // setRequirement(filteredData);
    //     }
    // }, [requireDetailsArr, name])

    const inputStyle = {
        fontFamily:'Kumbh Sans !important',
        fontSize:'1em',
        color:'#313131 !important',
        backgroundColor:'#FFF',
        border:'1px solid #CACACA',
        borderRadius:'5px',
        height:'1rem',
        width:'100%',
        padding: '1em',
        marginBottom: '1.5em'
    }

      const handleSubmit = (event) => {
        event.preventDefault();

        const { fname, lname, usermail, compdesc, userphone, careaexp, totalexp, positionapplied } = userInform;
        if(fname.trim() === '' ) { setValidate({bool:true, msg:'Write first name.'});  return; }
        if(lname.trim() === '' ) { setValidate({bool:true, msg:'Write last name.'});  return; }
        if(userphone.trim() === '') {setValidate({bool:true, msg:'Write correct phone number.'}); return;} 
        if(usermail.trim() === '' || !validateEmail(usermail) ) {setValidate({bool:true, msg:'Write correct email.'}); return;}
        if(compdesc.trim() === '' ) {setValidate({bool:true, msg:'Write company description.'}); return;}
        if(careaexp.trim() === '' ) {setValidate({bool:true, msg:'Write core experience.'}); return;}
        if(totalexp.trim() === '' ) {setValidate({bool:true, msg:'Write total experience.'}); return;}
        if(positionapplied.trim() === '' ) {setValidate({bool:true, msg:'Write position applied.'}); return;}




        if(!file) {setValidate({bool:true, msg:'Resume has not been attached. Please attach it'}); return;}
        console.log('wwwv', file, fileTypes.includes(file.type));
        if(!fileTypes.includes(file.type) ){setValidate({bool:true, msg:'Resume is not doc or pdf. Please attach correct one.'}); return;}
        if(file.size > 3000000 ){setValidate({bool:true, msg:'Resume file size is exceeding 3MB. Please attach smaller file size.'}); return;}
        Axios.post('/applymail.php', {name: `${fname} ${lname}`, phone:userphone, email:usermail, positionapplied, totalexp, careaexp, compdesc})
        .then(res => console.log('res ', res))
        .catch(error => console.log('error ', error))
        
        setStatus(true)


      }

      const handleChange = (event) => {
        // console.log('ee', event.target.files[0]);
        const sfile = event.target.files[0];
        console.log(sfile.type, sfile.size, fileTypes.includes(sfile.type));
        
        setFile(sfile);
      };

      const callUser = (event) =>{
        event.preventDefault();
        setStatus(false);
        const {id, value} = event.target;
        const tempUserInform = {...userInform};
        tempUserInform[id] = value
        setUserInform(tempUserInform);
    }


    return (
        <Grid className= {styles.applyForm_container} sx={{paddingBottom:"120px"}}>
            <Grid className={styles.applyForm_subcontainer}>
                <Typography variant="h3" component={"h3"} className={styles.applyForm_title}>
                    Reach Out to us
                </Typography>
                <Typography variant="body2" component={"p"} className={styles.applyForm_content}>
                    All fields are mandatory
                </Typography>
                <Grid sx={{marginTop:"2rem"}}>
                <form>
                    { !status && <>
                        <Grid sx={{width:{xs:"90%", sm:"70%"}}}>
                        <Grid>
                            <label htmlFor='fname' className={styles.applyForm_content}>First Name:</label>
                            <input type='text' id='fname' name='fname' value={userInform.fname} placeholder='' 
                                onChange={callUser} style={inputStyle} />
                        </Grid>
                        <Grid>
                            <label htmlFor='lname' className={styles.applyForm_content}>Last Name:</label>
                            <input type='text' id='lname' name='lname' value={userInform.fname} placeholder='' 
                                onChange={callUser} style={inputStyle} />
                        </Grid>
                        <Grid>
                            <label htmlFor='usermail' className={styles.applyForm_content}>Email:</label>
                            <input type='text' id='usermail' name='usermail' value={userInform.usermail} placeholder='' 
                                onChange={callUser} style={inputStyle} />
                        </Grid>
                        <Grid>
                            <label htmlFor='compdesc' className={styles.applyForm_content}>Comp description:</label>
                            <input type='text' id='compdesc' name='compdesc' value={userInform.compdesc} placeholder='' 
                                onChange={callUser} style={inputStyle} />
                        </Grid>
                        <Grid>
                            <label htmlFor='userphone' className={styles.applyForm_content}>Mobile:</label>
                            <input type='text' id='userphone' name='userphone' value={userInform.userphone} placeholder='' 
                                onChange={callUser} style={inputStyle} />
                        </Grid>
                        <Grid>
                            <label htmlFor='careaexp' className={styles.applyForm_content}>Core area of experience:</label>
                            <input type='text' id='careaexp' name='careaexp' value={userInform.careaexp} placeholder='' 
                                onChange={callUser} style={inputStyle} />
                        </Grid>
                        <Grid>
                            <label htmlFor='totalexp' className={styles.applyForm_content}>Years of experience:</label>
                            <input type='text' id='totalexp' name='totalexp' value={userInform.totalexp} placeholder='' 
                                onChange={callUser} style={inputStyle} />
                        </Grid>
                        <Grid>
                            <label htmlFor='positionapplied' className={styles.applyForm_content}>Position applying for:</label>
                            <input type='text' id='positionapplied' name='positionapplied' value={userInform.positionapplied} placeholder='' 
                                onChange={callUser} style={inputStyle} />
                        </Grid>

                        <Grid>
                            {/* <label for='uploadfile' className='normal-text title-color'>Attach Resume: </label> */}
                            <Button name='uploadfile' id='uploadfile' component="label" variant="contained" 
                                startIcon={<CloudUploadIcon />}>
                                Attach CV
                            <VisuallyHiddenInput type="file" 
                                onChange={(e) => handleChange(e)} 
                                accept={fileTypes.toString()} />
                            </Button>
                            <Typography variant='body2' component={'p'} className='normal-text' sx={{ fontSize:10, wordWrap:'break-word'}}>(.pdf, .doc, .docx only)</Typography>
                            {file && <Typography variant='body2' component={'p'} sx={{wordWrap:'break-word'}}>{file.name}</Typography>}
                        </Grid>
                        </Grid>
                        </> 
                    }
                        {validate.bool && !status && <Typography variant='body1' component={'p'} className='normal-text text-color' sx={{py:1}} >{validate.msg}</Typography>}
                        {status && <Typography variant='body2' component={'h4'} className='normal-text text-color' sx={{fontSize:'1.5 !important'}} >Thank you, your reume has been sent. We will be in touch shortly.</Typography>}
                        {!status && <Grid item sx={{display:'flex', columnGap:'0.5em', alignItems:'center', justifyContent:'flex-start', mt:'2em', width:'100%'}}>
                            <Button variant='contained' 
                                className= {styles.applyForm_btn}
                                endIcon={<East color="#FFFFFF" />}
                                onClick={handleSubmit}
                            >Submit</Button>
                        </Grid>
                    }
                        
                    </form>
                </Grid>

            </Grid>
        </Grid>
    )

}

export default ApplyForm;