import React from 'react';
import Grid from '@mui/material/Grid2'

import PageBanner from '../../components/pageBanner';
import PageContent from '../../components/pageContent';
import AdvantageC from '../../components/advantageC';
import CorporateTeam from './corporateTeam';
import Board from './board';
import { isMobile } from 'react-device-detect';



const LeadershipTeam = () => {

    const bannerPath = [
        {title: 'Home', url: '/'},
        {title: 'Know us', url: '/'},
        {title: 'Leadership Team', url: ''}
    ]

    return (
        <Grid sx={{display: 'flex', flexFlow:'column', width: '100%'}}>
            <PageBanner title= {isMobile ? "Leadership Team" : "Leadership<br/>Team"} bannerimage="leadership_banner.jpg" bannerPath={bannerPath} />
            <PageContent details="Simon India leadership team is the driving force behind its success and innovation. Comprising experienced professionals from diverse backgrounds, our Board of Directors and Corporate Leadership Team guide the company with vision, integrity, and a commitment to excellence. Their expertise in engineering, project management, and global strategy ensures that Simon India continues to be an industry  leader.
                <br/><br/>
                Meet the minds shaping our future and driving sustainable growth across all our operations."  />
            <Board />
            <CorporateTeam />
            <AdvantageC />
        </Grid>
    )

}

export default LeadershipTeam;

